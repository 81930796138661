import * as React from "react";
import Layout from "../components/Layout";
import * as Styles from "../styles/layout.module.scss";


const Kontakt = ({ pageContext }) => {
  
  return (
    <Layout
      title="Kontakt"
      pageContext={pageContext}
    >
      <div className={Styles.singleTopicLayout}>        
        <main>
          <h1>Kontakt</h1>
          <h2 id="directions">Anreise (Büro)</h2>
          <p>
            Brandeis Consulting GmbH <br/>
            Julius-Hattry Str. 1 <br/>
            68163 Mannheim <br/>
          </p>
          
        </main>
      </div> 
    </Layout>
  );
};

export default Kontakt;
